<template>
  <div class="reg">
    <div class="reg-header">
      <topNav></topNav>
    </div>
    <div class="reg-main">
      <div class="m-steps">
        <Steps :current="curIndex">
          <Step title="Email verification"></Step>
          <Step title="Add your Profile"></Step>
          <Step title="Registration completed"></Step>
        </Steps>
      </div>

      <div class="F">
        <img src="@/assets/img/icon-success.png" />
        <h3 v-if="!exhibitionConfig.visitorReg.regSuccessedNoticeEn">Welcome to {{ exhibitionInfo.nameEn }}</h3>
        <h3 v-else>{{ exhibitionConfig.visitorReg.regSuccessedNoticeEn }}</h3>
        <!-- <p style="text-align:center;">Dear {{ user.nameEn }},your code is ZGX10055548</p> -->
        <div style="padding-top: 30px; text-align:center;">
          <Button type="primary" ghost @click="$router.push(`/home/index/${exhibitionId}`)" size="large" class="btn" style="width: 180px;">Back to Homepage</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { validator } from "@/other/validator";
import topNav from "./components/top";

export default {
  name: "reg",
  data() {
    return {
      curIndex: 2,
    };
  },
  components: {
    topNav,
  },
  computed: {
    ...mapGetters({
      token: "getToken",
      company: "loginModule/getCompany",
      exhibitionInfo: "getExhibitionInfo",
      user: "getUser",
      exhibitionConfig: "getExhibitionConfig",
    }),
    exhibitionId() {
      return this.$route.params.id;
    },
  },
  created() {
    if (this.token) {
      this.getUser();
    }
  },
  methods: {
    ...mapMutations({
      setUser: "setUser",
      setUser: "setUser",
      setUserId: "setUserId",
      setLogin: "setLogin",
    }),
    ...mapActions({
      graphqlPost: "graphqlPost",
      linkToLogin: "linkTo/linkToLogin",
    }),
    async getUser() {
      let query = `
        query{
          memberQuery{
            current{
              address
              avatarUrl
              balance
              birthday
              businessCard
              city
              company 
              country
              county
              createAt
              deleteAt
              department
              email
              emailConfirmed
              followCount
              id
              inMember(exhibitionId:"${this.exhibitionId}"){ 
                business
                category
                id 
                memberId
                originId
                originLang
                isBlacklist
                originRemark
                originType
                regAt
                sayCount
                sayUserCount
                userId
                userRole
                exhibitorId
                companyUsers{
                  id
                  isAppointment
                  company {
                    nameEn
                  }
                }
              }
              introduction
              isDeleted
              jobTitle
              jobTitleEn
              name
              nameEn
              nickName
              phone
              phoneText
              phoneConfirm
              province
              talkCount
              talkSuccessedCount
              title
              updateAt
              userId
            }
          } 
        }
      `;
      let opt = {
        opt: {
          query: query,
        },
        token: this.token,
      };
      let data = await this.graphqlPost(opt);
      if (data.data && data.data.memberQuery && data.data.memberQuery.current) {
        this.setLogin(true);
        this.setUser(data.data.memberQuery.current);
        this.setUserId(data.data.memberQuery.current.userId);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.reg {
  &-header {
    box-shadow: 0px 2px 3px 0px rgba(101, 101, 101, 0.1);
  }
  &-main {
    width: 1200px;
    margin: 50px auto;
    .m-steps {
      width: 710px;
      margin: 0 auto;
      ::v-deep .ivu-steps-item {
        .ivu-steps-head {
          background: transparent;
        }
      }
    }
  }
  .F {
    width: 600px;
    margin: 34px auto;
    img {
      margin: 0 auto;
      display: block;
    }
    h3 {
      font-size: 18px;
      text-align: center;
      margin-top: 20px;
    }
  }
}
</style>
